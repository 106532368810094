import React, {useEffect, useState} from 'react';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import {Trans, useTranslation, withTranslation} from "react-i18next";
import styled from 'styled-components';
import GoogleAnalytics from '../GoogleAnalytics';
import cover from '../media/images/events/september/cover.png';
import RateUs from '../RateUs';
import Events from './clubevents/ClubEvents';
import ComingUp from './comingup/ComingUp';
import Copyright from './Copyright';
import Navigation from './Navigation';
import SectionDetails from './SectionDetails';
import SectionHeader from './SectionHeader';


const Wrapper = styled.div`
    padding-top: 150px;
    padding-bottom: 150px;
`;

const BackLink = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-top: 200px;
    margin-left: 16%;
    padding-bottom: 150px;
`;

const StyledCover = styled.img`
    position: relative;
    top: -50px;
    display: block;
    width: 50%;
    margin: 0 auto;
    @media (max-width: 1200px) {
        width: 90%;
    }
`

const Cover = () => <StyledCover src={cover} alt="Events, September 2024" title="Events, September 2024"/>

function EventsPage() {
    const {t, i18n} = useTranslation();
    ReactPixel.init('1576304659568289');
    ReactPixel.pageView();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, []);

    const listenToScroll = () => {
        let heightToHideFrom = 200;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    return (
        <Wrapper>
            <GoogleAnalytics/>
            <Helmet>
                <html lang={i18n.language}/>
                <meta charSet="utf-8"/>
                <title>{t('UPCOMING EVENTS')}</title>
                <meta name="description" content={t('UPCOMING EVENTS') + " " + t("September") + " 2024"}/>
                <link rel="canonical" href={"https://www.culturebeatclub.com/" + i18n.language + "/events"}/>
                <meta property="og:title" content={t('UPCOMING EVENTS')}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={cover}/>
                <meta property="og:url" content={"https://www.culturebeatclub.com/" + i18n.language + "/events"}/>
                <meta property="og:description" content={t('UPCOMING EVENTS') + " " + t("September") + " 2024"}/>
                <meta property="og:site_name" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"/>
            </Helmet>
            <Navigation/>
            <Cover/>
            <SectionHeader title={<Trans>UPCOMING EVENTS</Trans>}/>
            <SectionDetails title={<Trans>September</Trans>} year=" 2024"/>
            <Events/>
            <ComingUp/>
            <Copyright/>
            {isVisible ? (
                <RateUs/>
            ) : null}
        </Wrapper>
    );
}

export default withTranslation()(EventsPage);